import { Stack } from "@mui/material"
import React from "react"
import Body from "./Body"
import TopBar from "./TopBar"

const App = () => {
  return (
    <Stack>
      <TopBar />
      <Body />
    </Stack>
  )
}

export default App
